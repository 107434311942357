
import { defineComponent, onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// @import utils
import { IUserLevel } from '@/core/data/userLevel';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import RelatedContent from '@/components/related-content/RelatedContent.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import PlayListRuleModal from '@/components/modals/forms/PlayListRuleModal.vue';
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';

export default defineComponent({
	name: 'Update-Video-Content',
	components: {
		PlayListModal,
		PlayListRuleModal,
		RelatedContent,
		PricePlanModal,
		VideoContentModal,
		TemplateContentModal
	},
	setup() {
		const route = useRoute();
		const store = useStore();

		const activeModalKey = ref('');
		const imageModel = ref<any>(null);
		const contentEditor = ref<any>(null);
		const descriptionEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const loading = ref<boolean>(false);
		const relatedPlayListObj = ref<any>({});
		const videoContentArray = ref<any>([]);
		const detailImageUrl = ref<string>('');
		const videoContentIdArray = ref<any>([]);
		const overviewImageUrl = ref<string>('');
		const selectedPricingPlans = ref<any>([]);
		const userLevel = ref<Array<IUserLevel>>([]);
		
		const selectedRule = ref<any>(null);
		const isEdit = route.params.id;

		const formData = ref({
			id: '',
			title: '',
			displayTitle: '',
			buttonUrl: '',
			buttonLabel: '',
			publicationDate: '',
			displayAmount: 0,
			pricingPlans: '',
			playlistType: '',
			mediaSelection: '',
			ruleId: '',
			published: false,
			public: false,
			playlistItems: [],
			userLevels: [],
			created: '',
			updated: '',
			relatedPlayList: '',
			relatedContents: [],
			relatedContentsExpanded: []
		});

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			displayTitle: [
				{
					required: true,
					message: 'Display Title is required',
					trigger: 'change'
				}
			],
			displayAmount: [
				{
					required: true,
					message: 'URL is required',
					trigger: 'change'
				}
			]
		});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Playlist`, [
				'Playlist'
			]);
			if (isEdit) {
				getPlayListById();
			}
			getUserLevels();
		});

		const getPlayListRuleById = rId => {
			ApiService.get(`PlayListRule/${rId}`)
				.then(({ data }) => {
					setSelectedPlayListRule(data);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};
		const getVideoContentById = mId => {
			ApiService.get(`VideoContent/${mId}`)
				.then(({ data }) => {
					setSelectedVideoContent(data);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};
 
		const getPlayListById = () => {
			ApiService.get(`PlayList/${route.params.id}`)
				.then(({ data }) => {
					formData.value = {
						...formData.value,
						...data,
						userLevels:
							data.userLevels.indexOf(',') != -1 || data.userLevels != ''
								? data.userLevels.split(',')
								: (data.userLevels = [])
					};
					if (data.playlistItems && data.playlistItems.length) {
						for (const item of data.playlistItems) {
							getVideoContentById(item.videoContent);
						}
					}
					if(data.ruleId && data.mediaSelection === "rule")
					{
						getPlayListRuleById(data.ruleId);
					}

					if (data && data.pricingPlans) {
						console.log('data.pricingPlans: ', data.pricingPlans);
						getPricePlanList();
					}
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevel.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const getPricePlanList = () => {
			ApiService.get(`PricePlan`)
				.then(({ data }) => {
					console.log('formData', data, formData.value.pricingPlans);
					const filteredArray =
						data &&
						data.filter(el =>
							formData.value.pricingPlans.split(',').some(item => {
								console.log('loop', item, '--', el.pricePlanId);
								return item == el.pricePlanId;
							})
						);

					selectedPricingPlans.value = filteredArray;
				})
				.catch(({ response }) => {
					console.log('getPricePlanList response error:', response);
					loading.value = false;
				});
		};

		const setSelectedPlayList = row => {
			relatedPlayListObj.value = row;
			formData.value = {
				...formData.value,
				relatedPlayList: row.id
			};
		};

		const setSelectedVideoContent = row => {
			// console.log('selected video', { row });
			if (!videoContentIdArray.value.includes(row.id)) {
				videoContentArray.value.push(row);
				videoContentIdArray.value.push(row.id);
			}
		};

		const setSelectedPlayListRule = rule => {
			console.log('set selected play list rule', rule);
			formData.value.ruleId = rule.id
			selectedRule.value = rule
		}
		const resetVideoObject = id => {
			videoContentArray.value = videoContentArray.value.filter(
				e => e.id !== id
			);
			videoContentIdArray.value = videoContentIdArray.value.filter(
				e => e !== id
			);
		};

		const resetRuleObject = () => {
			selectedRule.value = null;
		}

		const setSelectedPricePlan = item => {
			// console.log('item:', item);
			selectedPricingPlans.value.push(item);
		};

		const resetPricingPlansObject = itemId => {
			selectedPricingPlans.value = selectedPricingPlans.value.filter(
				e => e.pricePlanId !== itemId
			);
		};

		const copy = async () => {
			var text = formData.value.title + " || " + formData.value.id;
			await navigator.clipboard.writeText(text);
		}

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			if (selectedPricingPlans.value.length) {
				const pricingPlansIds = selectedPricingPlans.value.map(
					item => item.pricePlanId
				);
				formData.value.pricingPlans = pricingPlansIds.join();
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = {
						...formData.value,
						customerId: selectedCustomer.value,
						userLevels: formData.value.userLevels.toString(),
						publicationDate: moment(formData.value.publicationDate).format(
							'YYYY-MM-DD'
						),
						playlistItems: videoContentArray.value.map(item => ({
							sortOrder: item.sortOrder,
							videoContent: item.id
						}))
					};
					if(data.mediaSelection === "rule")
					{
						delete data["relatedContents"]
						delete data["relatedContentsExpanded"]
						delete data["playlistItems"]
					}
					else if(data.mediaSelection === "handpick")
					{
						delete data["ruleId"]
					}
					// console.log({ data });
					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`PlayList/${route.params.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post('PlayList', data);
					}

					serviceType
						.then(({ data }) => {
							// console.log('create VideoContent response', data);
							loading.value = false;
							// redirect to main page ?
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = {
										...formData.value,
										...data,
										userLevels:
											data.userLevels.indexOf(',') != -1 ||
											data.userLevels != ''
												? data.userLevels.split(',')
												: (data.userLevels = [])
									};
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create video content error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			rules,
			moment,
			isEdit,
			copy,
			submit,
			selectedRule,
			formRef,
			loading,
			formData,
			userLevel,
			imageModel,
			contentEditor,
			detailImageUrl,
			resetVideoObject,
			resetRuleObject,
			overviewImageUrl,
			videoContentArray,
			descriptionEditor,
			selectedPricingPlans,
			resetPricingPlansObject,
			setSelectedPricePlan,
			setSelectedPlayListRule,
			setSelectedVideoContent,
			setSelectedPlayList
		};
	}
});
